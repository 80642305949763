@import url('https://fonts.googleapis.com/css2?family=Nosifer&display=swap');

html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  color: white;
  background-color: black;  
}

#root>div {
  height: 100%;
}

.content_rotate{
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg);
  transform-origin:top left;
  overflow: hidden;
  position: absolute;
  top: 100%;
  display: flex;
  align-items: center;
}

.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100vh ;
  box-shadow: inset 20px -20px 100px black;
  background: url('./assets/cricket_stadium .jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(4px);
}
/* THIS IS RUN WHEN ROTATE IN TRUE */
.background_images{
  position: absolute;
  width: 100%;
  height: 200vh ;
  box-shadow: inset 20px -20px 100px black;
  background: url('./assets/cricket_stadium .jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(4px);
}

.content_center {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  min-width: 100%;
}

.big_screen_content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content:center ;
  position: absolute;
  width: 100%;
  margin-top: 1%;
}

/* Scoreboard components */
.information {
  background-color: transparent;
}

/* this code are logo board-1 */
.logo_board {
  background-color: transparent;
  backdrop-filter: blur(100px);
  border-radius: 10px;
  padding: 20px;
  /* background-color: rgba(0,0,0, 0.1); */
  }

.logo_board_first_components {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.logo_board_secound_components {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo_board_third_components {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* logoboared-2 */
.logo_board_2 {
  padding: 10px;
}

.image {
  background-color: white;
  border: none;
}

/* Batsmen components */
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  background-color: transparent;
  letter-spacing: 1px;
  backdrop-filter: blur(30px);
  /* background-color: rgba(0,0,0, 0.1); */

}

th,
td {
  padding: 10px;
  text-align: center;
}

th:nth-child(1),
td:nth-child(1) {
  text-align: left;
}

/* this is in batsmen components */
.heads {
  background-color: white;
  color: black;
  backdrop-filter: opacity(1);
}

/* this is loading images */
.icons {
  overflow: hidden;
  background-color: aqua;
  width: 100px;
}

/* this is for poweredby box */
.poweredby_row {
  margin: 10px;
  padding: 10px; 
}

.poweredby_secound_Row {
  background-color: transparent;
  border-radius: 10px;
  justify-content: center;
  padding: 10px;
}

/* simple powerde */
.poweredBy {
  display: flex;
  justify-content: center;
}

.poweredBy_impactLogo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
}

.impactLogo_ImageTag{
  border-radius: 7px ;
}

.adstandee_logo{
  border-radius: 10px;
  padding: 6px;
}

/* this big and small screen classname are choose based in screen size */
.bigScreen {
  display: flex;
  justify-content: space-between;
}

.smallScreen{
  display: flex;
  justify-content:space-around;
}

.ambrased{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loadingIcon {
  overflow: hidden;
  max-width: 30%;
  min-width: 30%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
}

/* this is predict now */
.predict_now{
  font-size: xx-large;
  color:lightgreen;

}

/* pole and qr code component */

.custom-card {
  background-color: transparent ;
  display: flex;
  justify-content: center;
}
 
.custom-card .ant-card-head-title {
  white-space: normal !important; /* Ensure title text wraps */
  color: white;
  margin-bottom: 10px;
} 

.poll_and_qrCode{
  margin-top: 10px;
}

.button-wrapper {
  border-radius: 10px;
  background-color: #a1f3ac;
}

.button-wrapper_two {
  border-radius: 10px;
  background-color: #999aee;;
  margin-left: 10px;  
}

a{
  display: block;
  min-width: 70px;
  padding:0 10px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-decoration: none;
  color: black;
  border: 1px solid white;
  border-radius: 10px;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
}

a span{
  position: relative;
  z-index: 2;
}

/* this code for QR Code and QRCode headline */
.QRCode{
  margin-top: 20px;
}

.QRCOde_Headline{
  color: red;
}

/* this is date  */
.date{
  color: greenyellow; 
  margin-top: 10px;
  padding: 5px;
  font-size: larger;
  margin-bottom: -20px;
}


@media screen and (min-height: 900px) {
  .content_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo_board_font_style_media {
    font-size:xx-large;
    text-align: center;
  }

  .batter_bowler_table{
    font-size: xx-large;
  }
  
  .waiting_indicator{
    font-size: xx-large;
  }

  .custom-card .ant-card-head-title {
    font-size: x-large; 
  } 

  .QRCOde_Headline{
    color: white;
    margin-top: 10px;
    font-size: larger;
  }

  .QRCOde_Headline{
    font-size: x-large;
  }
   
  .poweredBy{
    font-size: xx-large;
  }

  .date{
     font-size: xx-large;
  }

  .big_screen_content{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
}

/* overriding Antd */

.ant-row {
  display: flex;
  justify-content:space-around;
}

